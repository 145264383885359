.c-pointer {
  cursor: pointer;
}
.react-select {
  .select__control {
    &.select__control--is-focused {
      border-color: $primary;
      box-shadow: none;
      border-width: 1px;
    }
  }
  &.is-invalid {
    .select__control {
      border-color: $danger !important;
    }
  }
}

.filter-search {
  input {
    border-right: 0px;
    &:focus {
      ~ .input-group-text {
        border-color: $primary;
      }
    }
  }
  .input-group-text {
    .filter-count {
      background-color: red;
      padding: 1px 5px;
      border-radius: 50px;
      color: white;
      position: absolute;
      margin: -5px 0 0 -7px;
      font-size: 0.6rem;
    }
  }
}
// open dashbaord

.legend {
  display: flex;
  flex-direction: column;
  align-items: start;
  .p-legend {
    display: flex;
    .planned-legend {
      background-color: #0069ff;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
    } 
    strong{
      font-size: 1rem;
    }
  }
  .up-legend {
    display: flex;
    margin-top: 10px;
    .unplanned-legend {
      background-color: #053375;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
    } 
    strong{
      font-size: 1rem;
    }
  }
}

.card.unplanned-card {
  background-color: #053375;
  height: auto;
  padding: 1.4rem;
  font-size: 2rem;
  .border-both {
    border-left: 5px solid white;
    border-right: 5px solid white;
    animation: changeBorderColor 5s infinite;
  }
  .border-r {
    border-right: 5px solid white;
  }

  .heading-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-text {
      font-size: 2.5rem;
      font-weight: 800;
      color: #eded22;
    }
    .body-text {
      font-size: 2rem;
      font-weight: 600;
      color: white;
    }
  }
  .update {
    margin: 10px 0px;
    padding: 5px 8px;
    color: white;
  }
  .problem {
    border-radius: 10px;
    border: 1px solid white;
    padding: 5px 10px;
    color: #053375;
    background-color: #e3e2e1;
  }
}

.status-btn {
  font-size: 1.5rem;
  padding: 10px 15px;
  border-radius: 10px;
  border: 2px solid white;
  color: white;
  margin-top: 5px;
}

.card.planned-card {
  background-color: #0069ff;
  height: auto;
  padding: 1.4rem;
  color: white;
  font-size: 2rem;
  .border-both {
    border-left: 5px solid white;
    border-right: 5px solid white;
    animation: changeBorderColor 5s infinite;
  }
  .border-r {
    border-right: 5px solid white;
  }

  .col-xl-3 {
    padding-bottom: 0.5rem;
  }
  .heading-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-text {
      font-size: 2.5rem;
      font-weight: 800;
      color: #f2ff0b;
    }
    .body-text {
      font-size: 2rem;
      font-weight: 600;
      color: white;
    }
  }
}
